<template>
  <nav
    class="bg-white px-4 py-3 flex flex-wrap items-center justify-between border-t border-gray-200 sm:px-6 shadow"
    aria-label="Pagination"
  >
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700">
        {{ $t("ticketTable.showing") }}
        {{ " " }}
        <span class="font-medium">{{ from }}</span>
        {{ " " }}
        {{ $t("ticketTable.to") }}
        {{ " " }}
        <span class="font-medium">{{ to }}</span>
        {{ " " }}
        {{ $t("ticketTable.of") }}
        {{ " " }}
        <span class="font-medium">{{ total }}</span>
        {{ " " }}
        {{ $t("ticketTable.results") }}
      </p>
    </div>

    <div class="flex-1 flex sm:justify-end pt-3 sm:pt-0">
      <a
        href="javascript:void(0)"
        @click="$emit('firstPage')"
        class="rounded ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table-pagination--button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
          />
        </svg>
      </a>
      <a
        href="javascript:void(0)"
        @click="$emit('previousPage')"
        class="rounded ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table-pagination--button"
      >
        {{ $t("ticketTable.previous") }}
      </a>

      <a
        href="javascript:void(0)"
        @click="$emit('nextPage')"
        class="rounded ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table-pagination--button"
      >
        {{ $t("ticketTable.next") }}
      </a>
      <a
        href="javascript:void(0)"
        @click="$emit('lastPage')"
        class="rounded ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table-pagination--button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 5l7 7-7 7M5 5l7 7-7 7"
          />
        </svg>
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TablePagination",
  props: ["from", "to", "total"],
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
