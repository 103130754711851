<template>
  <!-- Table -->
  <div>
    <div class="bg-white overflow-hidden sm:rounded-md" :key="this.showBody">
      <ul
        v-if="this.tablePaginatedData.length > 0"
        role="list"
        class="divide-y divide-gray-200"
      >
        <li
          v-for="(incomingEmail, idx) in this.tablePaginatedData"
          :key="incomingEmail.id"
        >
          <a
            href="javascript:void(0)"
            @click="
              this.setShowBody(idx),
                this.getStatusId(incomingEmail.ticket_id),
                this.getTicketInfos(incomingEmail.ticket_id)
            "
            class="block hover:bg-gray-50"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div
                  v-if="String(this.showIdx) === String(idx) && this.showBody"
                  class="flex-shrink-0 primary_text text-xl"
                >
                  <font-awesome-icon
                    icon="envelope-open-text"
                    class="icon alt"
                  />
                </div>
                <div v-else class="flex-shrink-0 primary_text text-xl">
                  <font-awesome-icon icon="envelope" class="icon alt" />
                </div>
                <div
                  class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4"
                >
                  <div>
                    <p
                      class="text-sm font-medium encom_primary_text_dark truncate"
                    >
                      {{ incomingEmail.subject }}
                    </p>
                    <p class="mt-2 flex items-center text-sm text-gray-500">
                      <span class="truncate">{{ incomingEmail.sender }}</span>
                      <font-awesome-icon
                        icon="right-from-bracket"
                        class="icon alt ml-2 mt-0.5"
                      />
                    </p>
                  </div>
                  <div class="hidden md:block">
                    <div>
                      <p class="text-sm text-gray-900">
                        Created on
                        {{ " " }}
                        <time :datetime="incomingEmail.created_at">
                          {{ formatDate(incomingEmail.created_at) }},
                          {{ formatHour(incomingEmail.created_at) }}</time
                        >
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500">
                        <font-awesome-icon
                          icon="right-to-bracket"
                          class="icon alt mr-2 mt-0.5"
                        />
                        {{ incomingEmail.recipient }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="String(this.showIdx) === String(idx) && this.showBody">
                <ChevronDownIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div v-else>
                <ChevronRightIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </a>
          <div
            v-if="String(this.showIdx) === String(idx) && this.showBody"
            class="px-10 pt-3 bg-gray-100 bg-opacity-75"
          >
            <div>
              <div
                v-if="this.outTickets"
                class="pb-5 mb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between"
              >
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Email not related to a client
                </h3>
                <div class="mt-3 flex sm:mt-0 sm:ml-4">
                  <div class="mt-3 sm:mt-0 sm:ml-4">
                    <label for="mobile-search-candidate" class="sr-only"
                      >Search</label
                    >
                    <label for="desktop-search-candidate" class="sr-only"
                      >Search</label
                    >
                    <div class="flex rounded-md shadow-sm">
                      <div class="relative flex-grow focus-within:z-10">
                        <div
                          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                        >
                          <SearchIcon
                            :class="[
                              this.isUnfinished
                                ? 'text-red-500'
                                : 'text-gray-400',
                              'h-5 w-5',
                            ]"
                            aria-hidden="true"
                          />
                        </div>
                        <QuickSearch
                          @action="this.customer_id = $event.searchable_id"
                          :placeholder="'Search'"
                          :isUnfinished="this.isUnfinished"
                          :incomingEmail="true"
                        />
                      </div>

                      <button
                        @click="
                          newTicket(
                            incomingEmail.id,
                            'New ticket created successfully'
                          )
                        "
                        type="button"
                        class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100"
                      >
                        Create Ticket
                      </button>
                    </div>
                    <p
                      v-show="this.isUnfinished"
                      class="mt-1 ml-2 text-sm text-red-600"
                      id="email-error"
                    >
                      Please, choose a client to create a ticket.
                    </p>
                  </div>
                  <button
                    @click="
                      deleteEmail(
                        incomingEmail.id,
                        `Delete this email`,
                        'Do you wish to continue?',
                        'Continue',
                        'Go back',
                        'Email deleted successfully'
                      )
                    "
                    type="button"
                    class="ml-3 inline-flex items-center px-4 py-2 h-1/2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Delete
                  </button>
                </div>
              </div>
              <div
                v-else
                class="pb-5 mb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between"
              >
                <div class="flex-1 min-w-0">
                  <h2 class="text-lg leading-6 font-medium text-gray-900">
                    Email from a closed ticket
                  </h2>
                  <div
                    class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
                  >
                    <div
                      v-if="this.ticket_subject"
                      @click="goToDetail(incomingEmail.ticket_id)"
                      class="mt-2 flex items-center text-sm text-gray-500 hover:text-gray-800 cursor-pointer"
                    >
                      <ExternalLinkIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      See ticket detail
                    </div>
                    <div
                      v-else
                      class="mt-2 flex items-center text-sm text-gray-500 hover:text-gray-800 cursor-pointer"
                    >
                      <ExclamationCircleIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      No ticket found
                    </div>
                    <div
                      v-if="this.ticket_contacts.length > 0"
                      class="mt-2 flex items-center text-sm text-gray-500"
                    >
                      <font-awesome-icon
                        icon="user-tie"
                        class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                      />
                      {{ this.ticket_contacts[0].firstName }}
                      {{ this.ticket_contacts[0].lastName }}
                      {{ incomingEmail.ticket_id }}
                    </div>

                    <!--  <div
                      v-if="this.ticket_contacts.length > 0"
                      class="mt-2 flex items-center text-sm text-gray-500"
                    >
                      <CalendarIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      Closing on January 1, 1901
                    </div> -->
                  </div>
                </div>
                <div class="mt-3 flex sm:mt-0 sm:ml-4">
                  <span class="relative z-0 inline-flex shadow-sm rounded-md">
                    <button
                      v-if="this.ticket_subject"
                      type="button"
                      @click="
                        newTicket(
                          incomingEmail.id,
                          'Ticket created successfully'
                        )
                      "
                      class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 encom_primary_border_focus"
                    >
                      Create New Ticket
                    </button>
                    <button
                      v-if="this.ticket_subject"
                      type="button"
                      @click="
                        reopenTicket(
                          incomingEmail.id,
                          'Ticket reopen successfully'
                        )
                      "
                      class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 encom_primary_border_focus"
                    >
                      Reopen Ticket
                    </button>
                  </span>
                  <button
                    @click="
                      deleteEmail(
                        incomingEmail.id,
                        `Delete this email`,
                        'Do you wish to continue?',
                        'Continue',
                        'Go back',
                        'Email deleted successfully'
                      )
                    "
                    type="button"
                    class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <span v-html="incomingEmail.body" class="text-gray-700"></span>
          </div>
        </li>
      </ul>
      <div v-else class="text-center py-10">
        <svg
          class="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vector-effect="non-scaling-stroke"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
          />
        </svg>

        <h3 class="mt-2 text-sm font-medium text-gray-900">No Emails</h3>
        <p class="mt-1 text-sm text-gray-500">
          They is no unlink email for the moment.
        </p>
      </div>
    </div>

    <BackendPagination
      v-if="this.backendPagination && tablePaginatedData.length"
      :to="this.to"
      :from="this.from"
      :total="this.total"
      @firstPage="$emit('firstPage')"
      @previousPage="$emit('previousPage')"
      @nextPage="$emit('nextPage')"
      @lastPage="$emit('lastPage')"
    />
  </div>

  <!-- dialog msg -->
  <ComfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
</template>

<script>
import axios from "axios";
import {
  CalendarIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ExternalLinkIcon,
  ExclamationCircleIcon,
  SearchIcon,
} from "@heroicons/vue/solid";
import { mapGetters } from "vuex";

import ComfirmationDialog from "../../ConfirmationDialog.vue";
import AskConfirmationDialog from "../../AskConfirmationDialog.vue";
import BackendPagination from "../../BackendPagination.vue";
import QuickSearch from "../../QuickSearch.vue";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

const account =
  localStorage.getItem("account") === ""
    ? this.$store.state.user.activeAccount
    : localStorage.getItem("account");

export default {
  name: "IncomingEmailsTable",
  props: [
    "tablePaginatedData",
    "backendPagination",
    "to",
    "from",
    "total",
    "lastPage",
    "outTickets",
  ],
  components: {
    CalendarIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    ExternalLinkIcon,
    ExclamationCircleIcon,
    SearchIcon,
    AskConfirmationDialog,
    BackendPagination,
    ComfirmationDialog,
    QuickSearch,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  data() {
    return {
      tableHeaders: [
        { name: "incomingEmailTable.subject", sort: "id", isSort: false },
        { name: "incomingEmailTable.sender", sort: "sender", isSort: false },
        {
          name: "incomingEmailTable.recipient",
          sort: "recipient",
          isSort: false,
        },
        {
          name: "incomingEmailTable.createdDate",
          sort: "created_at",
          isSort: false,
        },
      ],
      account,
      status: [],
      status_id: "",
      ticket_subject: "",
      ticket_contacts: [],
      isUnfinished: false,
      showBody: false,
      showIdx: "",
      showMessage: false,
      messageToShow: "",
      askConfirmation: false,
      confirmationMessage: "",
      subMessageToShow: "",
      quickData: [],
      search: "",
      customer_id: "",
      ticket_customer_id: "",
      activeSorting: "",
    };
  },
  methods: {
    async deleteEmail(
      id,
      title,
      confirmationMessage,
      confirmButton,
      goBack,
      successMsg
    ) {
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        axios
          .delete(`${this.$cookie.getCookie("API")}/api/v1/incomingMails/${id}`)
          .then((res) => {
            if (res) {
              this.$emit("activeBanner");
              this.$emit("success", true);
              this.$emit("successMsg", successMsg);
            }
          })
          .catch((error) => {
            this.errorHandling(error);
            this.$emit("activeBanner");
            this.$emit("success", false);
            this.$emit("successMsg", this.errorDataResponse);
          });
      }
    },

    async reopenTicket(id, successMsg) {
      const options = {
        method: "PUT",
        url: `${this.$cookie.getCookie(
          "API"
        )}/api/v1/incomingMails/${id}/reply`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios
        .request(options)
        .then((res) => {
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", successMsg);
        })
        .catch((error) => {
          this.errorHandling(error);
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", this.errorDataResponse);
        });
    },
    async newTicket(id, successMsg) {
      this.isUnfinished = false;
      if (this.outTickets) {
        this.isUnfinished = false;
        if (this.customer_id) {
          const options = {
            method: "PUT",
            url: `${this.$cookie.getCookie(
              "API"
            )}/api/v1/incomingMails/${id}/ticket`,
            params: {
              customer_id: this.customer_id,
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };

          axios
            .request(options)
            .then((res) => {
              this.$emit("activeBanner");
              this.$emit("success", true);
              this.$emit("successMsg", successMsg);
            })
            .catch((error) => {
              this.errorHandling(error);
              this.$emit("activeBanner");
              this.$emit("success", false);
              this.$emit("successMsg", this.errorDataResponse);
            });
        } else {
          this.isUnfinished = true;
        }
      } else {
        const options = {
          method: "PUT",
          url: `${this.$cookie.getCookie(
            "API"
          )}/api/v1/incomingMails/${id}/ticket`,
          params: { customer_id: this.ticket_customer_id },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };

        axios
          .request(options)
          .then((res) => {
            this.$emit("activeBanner");
            this.$emit("success", true);
            this.$emit("successMsg", successMsg);
          })
          .catch((error) => {
            this.errorHandling(error);
            this.$emit("activeBanner");
            this.$emit("success", false);
            this.$emit("successMsg", this.errorDataResponse);
          });
      }
    },
    async getTicketInfos(ticket_id) {
      if (ticket_id) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/tickets/${ticket_id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          this.ticket_contacts = res.data.contacts;
          this.ticket_customer_id = res.data.ticketable_id;
          this.isLoading = false;
        } catch (error) {
          console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
          this.isLoading = false;
        }
      } else {
        this.ticket_contacts = [];
      }
    },
    goToDetail(id) {
      let routeData = this.$router.resolve({
        path: `/tickets/${id}`,
      });
      window.open(routeData.href, "_blank");
    },
    checkStatus(id) {
      if (this.status) {
        for (let index = 0; index < this.status.length; index++) {
          if (this.status[index].id == id) {
            return this.status[index].name.slice(0, 10);
          }
        }
      }
    },
    async getStatus() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/statuses`
        );
        this.status = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getStatusId(id) {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/tickets/${id}`
        );
        this.status_id = res.data.status_id;
        this.ticket_subject = res.data.subject;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async setCustomerId(event) {
      this.customer_id = event.searchable_id;
    },
    setSorting(sort, header) {
      this.$emit("sorting", sort);
      this.activeSorting = header;
    },
    formatDate(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear();

      return formatedDate;
    },
    formatDateFrench(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear();

      return formatedDate;
    },
    formatHour(date) {
      const dateCreated = new Date(date);
      const formatedHour =
        ("0" + dateCreated.getHours()).slice(-2) +
        ":" +
        ("0" + dateCreated.getMinutes()).slice(-2);

      return formatedHour;
    },
    setId(id) {
      this.openForm = !this.openForm;
      this.editId = id;
    },
    setShowBody(idx) {
      // check if the index of the item on the list is different from the one that is active, and if the chart is opened
      // when clicked - if the index is not the one active - it will open the one that was clicked and close the one that was active
      if (this.showIdx !== "" && idx != this.showIdx && this.showBody) {
        this.showIdx = idx;
        this.showBody = true;
      } else {
        this.showBody = !this.showBody;
        this.showIdx = idx;
      }

      this.$forceUpdate();
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },

  mounted() {
    this.getStatus();
  },
  computed: {
    ...mapGetters(["active", "language"]),
  },
  watch: {
    search: function(val) {
      let splitValue = this.search.split(",");
      if (splitValue.length > 1) {
        this.customer_id = splitValue[1];

        this.search = splitValue[0];
      }
      this.getQuick(val);
    },
  },
};
</script>

<style>
.index_test {
  z-index: 10;
}
.subject_width {
  width: 500px;
}
.status_width {
  width: 210px;
}
.priority_selection {
  padding-block-start: 20px;
}
.pagination_button {
  padding: 8px;
  margin: 2px;
  border-radius: 3px;
  font-size: 1em;
  cursor: pointer;
}
html,
body {
  height: 100%;
}

@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    height: 50px;
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  td {
    height: 50px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }

  th {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    height: 50px;
  }
  .subject_width {
    width: auto;
  }
  .status_width {
    width: auto;
  }
}
</style>
